<template>
	<!-- 模板页面编辑 -->
	<div v-loading="loading" style="height: 100%;">
		<div v-if="id && template && template.name " style="height: 100%;" >
			 
					<HbiDesign style="height: 100%;overflow-y: auto;" ref="hbiDesign"  >
						<template slot="formName">
							{{template.name}}
						</template>
						<template slot="controlButton">
							<el-button   type="text" size="medium" icon="el-icon-circle-check" @click="handleSave">保存</el-button>
							<el-button   type="text" size="medium" icon="el-icon-close" @click="close">退出</el-button>
						</template>
					</HbiDesign>
			 
		</div>
		<div v-else>
			{{loading ? '加载中，请稍候...' : '模板不存在'}}
		</div>


	</div>

</template>
<script>
    import HbiDesign from '@/components/hbi/hbi-design'
    import { getById, updateInfo } from '@/api/hbi/report.js'
    import {userInfo} from '@/api/sys/user'
    export default{
        components:{
            HbiDesign
        },
        data(){
            return{
                loading: false,
                template: {},
                id: '',
            }
        },
        mounted(){
            this.id =  this.$route.query.id;

            this.init()
        },
      computed: {
        userId: {
          get() {
            return this.$store.state.user.id;
          },
          set(val) {
            this.$store.commit("user/updateId", val);
          }
        },
        deptId: {
          get() {
            return this.$store.state.user.deptId;
          },
          set(val) {
            this.$store.commit("user/updateDeptId", val);
          }
        },
      },
      created() {
        this.getUserInfo()
      },
        methods:{
          getUserInfo() {
            userInfo().then(({ data }) => {
              if (data && data.code === 0) {
                this.userId = data.data.userId;
                this.deptId = data.data.departmentId;
              }
            });
          },
            init(){
                this.loading = true

                getById(this.id).then(({data}) => {
                    if (data && data.code === 0) {
                     /*   // 判断ID 是否一致
                        if(data.data && this.id != data.data.id) {
                            this.$message({
                                message: '检测到数据已发生变化,以为您加载最新数据',
                                type: 'success',
                                duration: 1000
                            })

                            //url切换
                            this.$router.push({name:'hbi_report_edit',query: {id: data.data.id}});

                        }*/
                        this.template = data.data
                            // 取得表单
                            const formModel = this.template.templateData
                            const forms = formModel ? JSON.parse(formModel) : null

                            this.$nextTick(() => {
                                this.$refs.hbiDesign.initModel(forms)
                            })
                    } else {
                        this.id = ''
                    }
                })
                    .finally(()=>{
                        this.$nextTick(() => {
                            this.loading = false
                        })
                    })
            },

            handleSave(){
                // 获取数据
                const datas = this.$refs.hbiDesign.getModel()

                const htmlModelStr = JSON.stringify(datas)
                this.loading = true
                const data_ = {
                    'id': this.id  ,
                    'templateData': htmlModelStr
                }
                updateInfo(data_).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '保存成功',
                            type: 'success',
                            duration: 1000
                        })

						//修改后刷新页面
                        //window.opener.location.reload()//getReportList()
                    } else {
                        this.$message.error(data.msg)
                    }
                }).finally(()=>{
                    this.loading = false
                })

            },

			close(){
                window.close();
			}
        }

    }

</script>
<style lang="scss">
.form-design  {
    .el-card {
        height: 100%;
        .el-card__body {
            height: 100%;
        }
    }

    .el-container {
        overflow-y: hidden;
    }
    .form-panel {
        height: 100%;
    }

    .el-aside {
        .el-tabs {
            height: calc(100% - 25px);
            .el-tabs__content , el-tab-pane , .properties-centent {
                height: 100% ;
                overflow: auto;
            }
        }
        .properties-body {
            overflow: auto;
            height: 100%;
        }
    }
}    

</style>